import { algoliasearch } from "algoliasearch";

export const useAlgoliaSearch = () => {
	const {
		public: { algolia }
	} = useRuntimeConfig();

	const client = algoliasearch(
		(algolia as Record<string, string>).applicationId,
		(algolia as Record<string, string>).apiSearchKey
	);

	return {
		client
	};
};
